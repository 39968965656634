<template>
     <KCourse loader-class="MBcont" course-id="0" title="3 Шага к красивой кукле" :items="items">
         <div class="container">
            <iframe class="video" src="https://www.youtube.com/embed/Babk-ybqRHc" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
         </div>
     </KCourse>
</template>

<script>
    import KCourse from "../../../Widgets/KCourse";
    export default {
        name: "MBCourseHead",
        components: {
            KCourse,
        },
        data() {
        return {
            items: [
                { title: " Пропорции", url:"/mycourses/facemistakes/1"},
                { title: " Детали", url:"/mycourses/facemistakes/2"},
                { title: " Разбор", url:"/mycourses/facemistakes/3"},
            ],
        }
        }
    }
</script>

<style scoped>
    .container {
        position: relative;
        width: 100%;
        height: 0;
        padding-bottom: 56.25%;
        box-sizing: border-box;
        margin-bottom: 1em;
    }
    .video {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
    }
</style>